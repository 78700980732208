import React from 'react';
import Title from '@atoms/Title/Title';
import Text from '@atoms/Text/Text';
import Badge from '@atoms/Badge/Badge';
import Button from '@atoms/Button/Button';
import Section from '@atoms/Section/Section';
import './OrderedCard.scss';
import Link from '@components/Link';
import GatsbyResponsiveImage from '@atoms/GatsbyResponsiveImage/GatsbyResponsiveImage';

interface Props {
    highlightedText?: string;
    title: string;
    subtitle?: string;
    children?: React.ReactNode;
    images?: any;
    badges?: any;
    buttons?: any;
}
const OrderedCard: React.FC<Props> = ({
    highlightedText,
    title,
    subtitle,
    children,
    images,
    badges,
    buttons,
}) => {
    const renderImages = () => {
        if (!images) return <></>;
        return images.map((item: any) => {
            const {
                media,
                src: { data },
            } = item;
            if (!data) return <></>;
            const {
                attributes: { urlSharp, url },
            } = data;
            if (media === 'mobile')
                return (
                    <GatsbyResponsiveImage
                        desktopClassName="g_show_only_mobile"
                        urlSharp={urlSharp}
                        url={url}
                    />
                );
            return (
                <GatsbyResponsiveImage
                    desktopClassName="g_hide_mobile"
                    urlSharp={urlSharp}
                    url={url}
                />
            );
        });
    };

    const renderBadges = () => {
        if (!badges) return;
        return badges.map(({ variant, label, size }: any) => (
            <Section spacingBottom="xxs">
                <Badge variant={variant} size={size || 's'}>
                    {label}
                </Badge>
            </Section>
        ));
    };

    const renderLink = (props: any) => {
        if (props.to.startsWith('https://') || props.to.startsWith('http://')) {
            return (
                <a href={props.to} target="_blank">
                    <Button {...props} isMobileFullsize />
                </a>
            );
        }

        return (
            <Link to={props.to}>
                <Button {...props} isMobileFullsize />
            </Link>
        );
    };

    const renderButtons = () => {
        if (!buttons) return;
        return buttons.map((props: any) => (
            <Section spacingTop="xs">
                {props.to ? (
                    renderLink(props)
                ) : (
                    <Button {...props} isMobileFullsize />
                )}
            </Section>
        ));
    };

    const hasImage = !images || (images && images?.length === 0);
    return (
        <div className={`OrderedCard ${hasImage ? '' : 'has-image'}`}>
            {images?.length ? (
                <div className="OrderedCard--visual">{renderImages()}</div>
            ) : null}

            <div className="OrderedCard--content">
                {highlightedText && (
                    <Title size="xxl" font="alt" theme="light">
                        {highlightedText}
                    </Title>
                )}

                <Title size="ml">{title}</Title>
                {renderBadges()}
                {subtitle && <Text size="m">{subtitle}</Text>}
                {renderButtons()}
                {children && children}
            </div>
        </div>
    );
};

export default OrderedCard;
